import {
  Avatar,
  Center,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Select,
  Stack,
} from "@chakra-ui/react";
import { FC } from "react";
import {
  FormState,
  UseFormHandleSubmit,
  UseFormRegister,
} from "react-hook-form";
import { MemberFrame } from "../../../../@types/Member";
import { EMPTY_STRING } from "../../../../lib/Const";
import { UserDataForm } from "../../../../lib/hooks/ManagementSettings/useChangeUserProfile";
import { getImageUrl, getName } from "../../../../lib/util/helper";
import { useAppSelector } from "../../../../redux/storeConfig";
import { ButtonSubmit } from "../Button/ButtonSubmit";
import { TextHeading } from "../Typography/TextHeading";
import { TextSecond } from "../Typography/TextSecond";

type UserFormType = {
  userData: MemberFrame | null;
  formState: FormState<UserDataForm>;
  handleSubmit: UseFormHandleSubmit<UserDataForm, UserDataForm>;
  register: UseFormRegister<UserDataForm>;
  onSubmit: (_values: UserDataForm) => void;
};

export const UserForm: FC<UserFormType> = (props) => {
  const { formState, userData, handleSubmit, onSubmit, register } = props;
  const { isLoading } = useAppSelector((state) => state.loading);
  const { adminAuthorityList: adminAuthority } = useAppSelector(
    (state) => state.userInfo
  );
  const { errors } = formState;

  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <Stack gap="19px">
        <Flex direction="column" align="center" justify="center" gap="10px">
          <Avatar
            src={getImageUrl(userData?.gtuserpic || EMPTY_STRING)}
            w="70px"
            h="70px"
            display="block"
          />
          <Stack gap="4px">
            <TextHeading textAlign="center" fontSize="14px">
              {getName([
                userData?.gtuserlastname || EMPTY_STRING,
                userData?.gtuserfirstname || EMPTY_STRING,
              ])}
            </TextHeading>
            <TextSecond textAlign="center" color="#A5A5A5" fontSize="12px">
              {getName([
                userData?.gtuserlastnamekana || EMPTY_STRING,
                userData?.gtuserfirstnamekana || EMPTY_STRING,
              ])}
            </TextSecond>
          </Stack>
        </Flex>
        <Grid templateColumns="repeat(2, 1fr)" gap="30px" rowGap="20px">
          <GridItem>
            <Grid templateColumns="repeat(2, 1fr)" gap="30px" rowGap="20px">
              <GridItem>
                <FormControl isInvalid={!!errors.lastname}>
                  <FormLabel mb="10px">
                    <TextHeading>姓</TextHeading>
                  </FormLabel>
                  <Input
                    disabled={isLoading}
                    variant="custom"
                    {...register("lastname")}
                  />
                  <FormErrorMessage fontSize="14px">
                    {errors.lastname && errors.lastname.message}
                  </FormErrorMessage>
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl isInvalid={!!errors.firstname}>
                  <FormLabel mb="10px">
                    <TextHeading>名</TextHeading>
                  </FormLabel>
                  <Input
                    disabled={isLoading}
                    variant="custom"
                    {...register("firstname")}
                  />
                  <FormErrorMessage fontSize="14px">
                    {errors.firstname && errors.firstname.message}
                  </FormErrorMessage>
                </FormControl>
              </GridItem>
            </Grid>
          </GridItem>
          <GridItem>
            <Grid templateColumns="repeat(2, 1fr)" gap="30px" rowGap="20px">
              <GridItem>
                <FormControl isInvalid={!!errors.lastnamekana}>
                  <FormLabel mb="10px">
                    <TextHeading>姓(フリガナ)</TextHeading>
                  </FormLabel>
                  <Input
                    disabled={isLoading}
                    variant="custom"
                    {...register("lastnamekana")}
                  />
                  <FormErrorMessage fontSize="14px">
                    {errors.lastnamekana && errors.lastnamekana.message}
                  </FormErrorMessage>
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl isInvalid={!!errors.firstnamekana}>
                  <FormLabel mb="10px">
                    <TextHeading>名(フリガナ)</TextHeading>
                  </FormLabel>
                  <Input
                    disabled={isLoading}
                    variant="custom"
                    {...register("firstnamekana")}
                  />
                  <FormErrorMessage fontSize="14px">
                    {errors.firstnamekana && errors.firstnamekana.message}
                  </FormErrorMessage>
                </FormControl>
              </GridItem>
            </Grid>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors.title}>
              <FormLabel mb="10px">
                <TextHeading>肩書</TextHeading>
              </FormLabel>
              <Input
                variant="custom"
                disabled={isLoading}
                {...register("title")}
              />
              <FormErrorMessage fontSize="14px">
                {errors.title && errors.title.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors.occupation}>
              <FormLabel mb="10px">
                <TextHeading>役職</TextHeading>
              </FormLabel>
              <Input
                variant="custom"
                disabled={isLoading}
                {...register("occupation")}
              />
              <FormErrorMessage fontSize="14px">
                {errors.occupation && errors.occupation.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors.location}>
              <FormLabel mb="10px">
                <TextHeading>勤務地</TextHeading>
              </FormLabel>
              <Input
                variant="custom"
                disabled={isLoading}
                {...register("location")}
              />
              <FormErrorMessage fontSize="14px">
                {errors.location && errors.location.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors.contract}>
              <FormLabel mb="10px">
                <TextHeading>勤務形態</TextHeading>
              </FormLabel>
              <Input
                variant="custom"
                disabled={isLoading}
                {...register("contract")}
              />
              <FormErrorMessage fontSize="14px">
                {errors.contract && errors.contract.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors.authority}>
              <FormLabel mb="10px">
                <TextHeading>権限</TextHeading>
              </FormLabel>
              <Select
                variant="custom"
                disabled={isLoading}
                {...register("authority")}
              >
                {adminAuthority.map((authority) => (
                  <option
                    key={authority.gtauthorityid}
                    value={authority.gtauthorityid}
                  >
                    {authority.gtauthorityname}
                  </option>
                ))}
              </Select>
              <FormErrorMessage fontSize="14px">
                {errors.authority && errors.authority.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl>
              <FormLabel mb="10px">
                <TextHeading>メールアドレス</TextHeading>
              </FormLabel>
              <TextSecond lineHeight="20px" fontWeight="bold">
                {userData?.gtuserid}
              </TextSecond>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl>
              <FormLabel mb="10px">
                <TextHeading>所属チーム</TextHeading>
              </FormLabel>
              <TextSecond lineHeight="20px" fontWeight="bold">
                {userData?.gtteamname}
              </TextSecond>
            </FormControl>
          </GridItem>
        </Grid>
      </Stack>
      <Center pt="30px" pb="20px">
        <ButtonSubmit type="submit" isLoading={isLoading}>
          確定
        </ButtonSubmit>
      </Center>
    </form>
  );
};
