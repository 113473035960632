import {
  Avatar,
  Center,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Select,
  Stack,
} from "@chakra-ui/react";
import { FC } from "react";
import {
  FormState,
  UseFormHandleSubmit,
  UseFormRegister,
} from "react-hook-form";
import { MemberFormType } from "../../../../lib/hooks/ManagementSettings/useInviteMember";
import { useAppSelector } from "../../../../redux/storeConfig";
import { ButtonSubmit } from "../Button/ButtonSubmit";
import { TextHeading } from "../Typography/TextHeading";

type InviteMemberFormType = {
  formState: FormState<MemberFormType>;
  handleSubmit: UseFormHandleSubmit<MemberFormType, MemberFormType>;
  register: UseFormRegister<MemberFormType>;
  onSubmit: (_values: MemberFormType) => void;
};

export const InviteMemberForm: FC<InviteMemberFormType> = (props) => {
  const { formState, handleSubmit, onSubmit, register } = props;
  const { isLoading } = useAppSelector((state) => state.loading);
  const { adminAuthorityList: adminAuthority } = useAppSelector(
    (state) => state.userInfo
  );
  const { errors } = formState;

  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <Stack gap="19px">
        <Flex direction="column" align="center" justify="center" gap="10px">
          <Avatar src="" w="70px" h="70px" display="block" />
        </Flex>
        <Grid templateColumns="repeat(2, 1fr)" gap="30px" rowGap="20px">
          <GridItem>
            <Grid templateColumns="repeat(2, 1fr)" gap="30px" rowGap="20px">
              <GridItem>
                <FormControl isInvalid={!!errors.lastname}>
                  <FormLabel mb="10px">
                    <TextHeading>姓</TextHeading>
                  </FormLabel>
                  <Input
                    disabled={isLoading}
                    variant="custom"
                    {...register("lastname")}
                  />
                  <FormErrorMessage fontSize="14px">
                    {errors.lastname && errors.lastname.message}
                  </FormErrorMessage>
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl isInvalid={!!errors.firstname}>
                  <FormLabel mb="10px">
                    <TextHeading>名</TextHeading>
                  </FormLabel>
                  <Input
                    disabled={isLoading}
                    variant="custom"
                    {...register("firstname")}
                  />
                  <FormErrorMessage fontSize="14px">
                    {errors.firstname && errors.firstname.message}
                  </FormErrorMessage>
                </FormControl>
              </GridItem>
            </Grid>
          </GridItem>
          <GridItem>
            <Grid templateColumns="repeat(2, 1fr)" gap="30px" rowGap="20px">
              <GridItem>
                <FormControl isInvalid={!!errors.lastnamekana}>
                  <FormLabel mb="10px">
                    <TextHeading>姓(フリガナ)</TextHeading>
                  </FormLabel>
                  <Input
                    disabled={isLoading}
                    variant="custom"
                    {...register("lastnamekana")}
                  />
                  <FormErrorMessage fontSize="14px">
                    {errors.lastnamekana && errors.lastnamekana.message}
                  </FormErrorMessage>
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl isInvalid={!!errors.firstnamekana}>
                  <FormLabel mb="10px">
                    <TextHeading>名(フリガナ)</TextHeading>
                  </FormLabel>
                  <Input
                    disabled={isLoading}
                    variant="custom"
                    {...register("firstnamekana")}
                  />
                  <FormErrorMessage fontSize="14px">
                    {errors.firstnamekana && errors.firstnamekana.message}
                  </FormErrorMessage>
                </FormControl>
              </GridItem>
            </Grid>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors.title}>
              <FormLabel mb="10px">
                <TextHeading>肩書</TextHeading>
              </FormLabel>
              <Input
                variant="custom"
                disabled={isLoading}
                {...register("title")}
              />
              <FormErrorMessage fontSize="14px">
                {errors.title && errors.title.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors.occupation}>
              <FormLabel mb="10px">
                <TextHeading>役職</TextHeading>
              </FormLabel>
              <Input
                variant="custom"
                disabled={isLoading}
                {...register("occupation")}
              />
              <FormErrorMessage fontSize="14px">
                {errors.occupation && errors.occupation.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors.location}>
              <FormLabel mb="10px">
                <TextHeading>勤務地</TextHeading>
              </FormLabel>
              <Input
                variant="custom"
                disabled={isLoading}
                {...register("location")}
              />
              <FormErrorMessage fontSize="14px">
                {errors.location && errors.location.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors.contract}>
              <FormLabel mb="10px">
                <TextHeading>勤務形態</TextHeading>
              </FormLabel>
              <Input
                variant="custom"
                disabled={isLoading}
                {...register("contract")}
              />
              <FormErrorMessage fontSize="14px">
                {errors.contract && errors.contract.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors.authority}>
              <FormLabel mb="10px">
                <TextHeading>権限</TextHeading>
              </FormLabel>
              <Select
                variant="custom"
                disabled={isLoading}
                {...register("authority")}
              >
                {adminAuthority.map((authority) => (
                  <option
                    key={authority.gtauthorityid}
                    value={authority.gtauthorityid}
                  >
                    {authority.gtauthorityname}
                  </option>
                ))}
              </Select>
              <FormErrorMessage fontSize="14px">
                {errors.authority && errors.authority.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors.email}>
              <FormLabel mb="10px">
                <TextHeading>メールアドレス</TextHeading>
              </FormLabel>
              <Input
                variant="custom"
                disabled={isLoading}
                {...register("email")}
              />
              <FormErrorMessage fontSize="14px">
                {errors.email && errors.email.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
        </Grid>
      </Stack>
      <Center pt="30px" pb="20px">
        <ButtonSubmit type="submit" isLoading={isLoading}>
          確定
        </ButtonSubmit>
      </Center>
    </form>
  );
};
